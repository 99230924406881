.sideBar {
  background-color: var(--primary-color);
  padding: 24px;
  width: 224px;

  height: 100vh;
  overflow-y: scroll;

  .sideBarItems {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .sideBarItem {
      cursor: pointer;
      user-select: none;
      border-radius: 8px;
      padding: 12px 14px;
      display: flex;
      gap: 8px;
      color: white;
      align-items: center;
      text-decoration: none;
      p {
        font-size: 12px;
        font-weight: 500;
      }

      .sideBarItemIcon {
        color: white;
      }
    }

    .sideBarItem:hover {
      background-color: white;
      p {
        color: var(--primary-color);
      }
      .sideBarItemIcon {
        color: var(--primary-color);
      }
    }

    .selectedSideBarItem{
      background-color: white;
      p {
        color: var(--primary-color);
      }
      .sideBarItemIcon {
        color: var(--primary-color);
      }
    }
  }
}
