.pageTitle{
  margin-bottom: 16px;
}

.reviewProduct {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 90vh;
  min-height: 40vh;
  justify-content: space-between;
  //   overflow: scroll;

  padding-block: 12px;

  .productImage {
    height: 150px;
    width: 200px;
  }
}

.reviewProductBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;

  width: 700px;
  max-width: 90vw;
  max-height: 90vh;

  padding: 16px;
  padding-bottom: 24px;
  overflow: scroll;
}

.cropperDiv{
  border: 1px solid red;
}