* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.loginPage {
  display: flex;
  align-items: center;
  background: #f6f8fb;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 8%;
  gap: 24px;

  .loginSquare {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background-color: #fefefe;
    box-shadow: 2px 12px 16px 0px rgba(165, 164, 164, 0.25);
    padding-inline: 24px;
    padding-block: 32px;
    width: 416px;
    max-width: 100vw;
    gap: 32px;

    .loginForm {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
    }
  }
}
