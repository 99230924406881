.inline-list {
    display: flex;
    flex-wrap: wrap;
  }
  
  .inline-item {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .radioContainer  {
    display: flex;
    margin: 20px;
  }
  
  
  label {
    display: inline-block;
    margin-right: 20px;
  }
  