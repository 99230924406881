* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

p {
  margin: 0;
}

input,
select {
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.app {
  display: flex;
  max-height: 100vh;

  .appBody {
    overflow-y: scroll;
    width: 100%;
    height: 100vh;
  }
}

.pageTitle {
  color: #333;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.page {
  background-color: var(--primary-background-color);
  padding-inline: 40px;
  padding-block: 32px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .pageUpper {
    display: flex;
    align-items: center;
    justify-content: space-between;


    .pageUpperCTAs {
      display: flex;
      gap: 20px;
    }
  }
}
