.customButton {
  // width: fit-content;
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  border: 1px solid var(--primary-color);


  .customButtonText {
    color: white;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
  }
}

.customButtonSecondary {
  background-color: white;
  border: 1px solid var(--primary-color);
  .customButtonText {
    color: var(--primary-color);
  }
}
